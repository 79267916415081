import React, { useEffect } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useConfig } from "../../contexts/configContext";

const Dashboard = (props) => {
  const config = useConfig();
  const namespace = config.namespace;

  useEffect(() => {
    props.setBreadcrumb("", "Dashboard", "");
  }, []);

  return (
    <div className="page">
      <Tabs
        defaultActiveKey="Application Alarm"
        id="sepp-tabs-dashboard"
        className="mb-3"
        justify
      >
        <Tab eventKey="Application Alarm" title="APPLICATION ALARM">
          <iframe
            style={{ marginTop: "20px" }}
            src={`${config.configInfo.grafanaGlobalUrl}d/a53530b6-dc80-4f08-9970-a488b745e14b/application-alarm?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="Platform Alarm" title="PLATFORM ALARM">
          <iframe
            style={{ marginTop: "20px" }}
            src={`${config.configInfo.grafanaLocalUrl}d/fb9ab0e1-e2dc-4477-962b-58372ff1f76d/platform-alarm?orgId=1&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="Application Performance" title="APPLICATION PERFORMANCE">
          <iframe
            style={{ marginTop: "20px" }}
            src={`${config.configInfo.grafanaGlobalUrl}d/eee539c6-35ae-4788-9405-83e32e25c1cc/application-performance?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="Platform Performance" title="PLATFORM PERFORMANCE">
          <iframe
            style={{ marginTop: "20px" }}
            src={`${config.configInfo.grafanaLocalUrl}d/c32a20bb-c2d6-4d92-85ff-3363439d3cd2/platform-performance?orgId=1&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
        <Tab eventKey="SEPP Capacity" title="SEPP CAPACITY">
          <iframe
            style={{ marginTop: "20px" }}
            src={`${config.configInfo.grafanaLocalUrl}d/ff68a132-fbec-4a2b-987d-ab40e57b766c/sepp-capacity?orgId=1&var-namespace=${namespace}&kiosk`}
            width="90%"
            height="650px"
          ></iframe>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Dashboard;
