import React, { useEffect, useState } from "react";
import "./Monitoring.less";
import { useConfig } from "../../contexts/configContext";
import { useSnackbar } from "../../contexts/snackbarContext";
import Spinner from "../UI/Spinner/Spinner";
import useHttpApi from "../useHttpApi";
import ListTable from "../SharedComponents/ListTable";
import axios from "axios";
import { Button } from "react-bootstrap";
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function Monitoring(props) {
  const [alarmTable, setalarmTable] = useState([]);
  const [fetchedAlarmTable, setfetchedAlarmTable] = useState(null);
  const [openConfirmModal, setopenConfirmModal] = useState(false);
  const config = useConfig();
  const snackbar = useSnackbar();
  const httpApi = useHttpApi();

  const header = [
    {
      title: "Object Instance",
      field: "objectInstance",
      size: "12%",
    },
    {
      title: "Notification ID",
      field: "notificationId",
      size: "9%",
    },
    {
      title: "Notification Type",
      field: "notificationType",
      size: "9%",
    },
    {
      title: "Alarm ID",
      field: "alarmId",
      size: "6%",
    },
    {
      title: "Probable Cause",
      field: "probableCause",
      size: "11%",
    },
    {
      title: "Specific Problem",
      field: "specificProblem",
      size: "9%",
    },
    {
      title: "Severity",
      field: "perceivedSeverity",
      size: "7%",
    },
    {
      title: "Involved Counterpart",
      field: "involvedCounterpart",
      size: "9%",
    },
    {
      title: "Event Time",
      field: "eventTime",
      size: "12%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ];

  useEffect(() => {
    props.setBreadcrumb("", "Monitoring", "");
  }, []);

  useEffect(() => {
    getAlarmTable().then((res) => {
      if (res !== -1) setfetchedAlarmTable(res);
    });
  }, []);

  const getAlarmTable = async () => {
    const data = await httpApi.callGetAPI({
      url: `json/${config.configInfo.protectionGroupID}alarm-table`,
      snack: {
        info: { status: "info", message: `There are no alarm data to show.` },
      },
    });
    return data || -1;
  };

  const resetAlarms = async () => {
    const promises = Promise.all([
      await httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}alarm-table`,
        data: {},
        snack: {
          show: false,
        },
        successCallback: () =>
          getAlarmTable().then((res) => {
            if (res !== -1) setfetchedAlarmTable(res);
            return {
              status: 1,
              name: `${config.configInfo.protectionGroupID}alarm-table`,
            };
          }),
        errorCallback: () => ({
          status: -1,
          name: `${config.configInfo.protectionGroupID}alarm-table`,
        }),
      }),

      await httpApi.callPostAPI({
        url: `json/${config.configInfo.protectionGroupID}event-table`,
        data: {},
        snack: {
          show: false,
        },
        successCallback: () => ({
          status: 1,
          name: `${config.configInfo.protectionGroupID}event-table`,
        }),
        errorCallback: () => ({
          status: -1,
          name: `${config.configInfo.protectionGroupID}event-table`,
        }),
      }),
    ]);
    console.log(promises);

    promises.then((promise) => {
      console.log(promise);
      const errors = promise.filter((el) => el.status === -1);
      if (errors.length > 0)
        snackbar.setSnackbarInfo({
          status: "error",
          message: `An error occurred while saving ${errors.join(", ")} data`,
        });
      else {
        snackbar.setSnackbarInfo({
          status: "success",
          message: `Data successfully saved!`,
        });
      }
    });
  };

  const buildAlarmTable = (fetchedAlarmTable) => {
    const _alarmTable = new Set();
    //getting the mib object
    Object.entries(fetchedAlarmTable).map((k, v) => {
      const mib = k[1];
      //extracting the mib id and the alarm object
      return Object.entries(mib).map((k1, v1) => {
        const alarmObject = k1[1];
        //build the alarm object by checking the mib id on the mibTable (in the config file)
        return alarmObject.map((alarm) => {
          if (alarm.model) {
            alarm.model.involvedCounterpart =
              alarm.model.additionalInformation.counterPart;

            // alarm.model.notificationType!=='notifyClearedAlarm' && _alarmTable.add(alarm.model)
            _alarmTable.add(alarm.model);
          }
          return alarm;
        });
      });
    });

    return Array.from(_alarmTable);
  };

  useEffect(() => {
    if (fetchedAlarmTable) {
      try {
        const _alarmTable = buildAlarmTable(fetchedAlarmTable);
        setalarmTable(_alarmTable);
      } catch (error) {
        snackbar.setSnackbarInfo({
          status: "error",
          message: `<b>An error occurred:</b> ${error
            .toString()
            .replace("Error:", "")}`,
        });
      }
    }
  }, [config.namespace, fetchedAlarmTable]);

  const produceJsonTable = () => {
    let filteredAlarmTable = [];
    alarmTable.forEach((alarm) => {
      let obj = {};
      header.forEach((h) => {
        Object.keys(alarm).forEach((key) => {
          console.log(h.field, key);
          if (key === h.field) {
            obj = { ...obj, [key]: alarm[key] };
          }
        });
      });
      filteredAlarmTable = [...filteredAlarmTable, obj];
    });
    return filteredAlarmTable;
  };

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "alarmsTable.xlsx");
  };

  const downloadData = () => {
    const jsonAlarmTable = produceJsonTable();
    exportToExcel(jsonAlarmTable);
  };

  return (
    <>
      {config.loading ? (
        <Spinner show={config.loading} />
      ) : (
        <>
          <ConfirmModal
            bsSize="medium"
            show={openConfirmModal}
            onHide={() => setopenConfirmModal(false)}
            onClose={() => setopenConfirmModal(false)}
            title="Alarm Reset"
            className="text-center"
            onAccept={(e) => {
              setopenConfirmModal(false);
              resetAlarms();
            }}
            closeText="Close"
          >
            <div>
              <p>Are you sure you want to reset all the alarms?</p>
            </div>
          </ConfirmModal>
          <div className="page">
            {alarmTable.length > 0 ? (
              <>
                <ListTable
                  elements={alarmTable}
                  header={header}
                  sortable
                  searchable
                  collapsible
                  dense
                  cellActions={[
                    {
                      elementName: "alarm",
                      type: "show",
                      size: header.find(
                        (h) => h.hasOwnProperty("type") && h.type === "show"
                      )?.size,
                    },
                  ]}
                ></ListTable>
                <div
                  className="rowDiv j-right list-group"
                  style={{ marginRight: "1.5%", marginTop: "0px" }}
                >
                  <Button
                    onClick={() => setopenConfirmModal(true)}
                    style={{ marginLeft: "20px" }}
                  >
                    RESET ALL
                  </Button>
                  <Button
                    onClick={() => downloadData()}
                    style={{ marginLeft: "20px" }}
                  >
                    DOWNLOAD
                  </Button>
                </div>
              </>
            ) : (
              <h3 className="no-table-data">No data</h3>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Monitoring;
