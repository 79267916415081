import {
  faArrowsLeftRight,
  faMagnifyingGlass,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { isNonEmptyString, isNonEmptyArray } from "../../utils";
import TippedCell from "./TippedCell";
import DeleteAction from "./TableActions/DeleteAction";
import "../../App.less";
import CrossedInput from "./CrossedInput";
import ShowAction from "./TableActions/ShowAction";

function ListTable(props) {
  const [header, setheader] = useState(null);
  const isSortable = props.sortable || false;
  const isSearchable = props.searchable || false;
  const isCollapsible = props.collapsible || false;
  const originalElements = useRef(props.elements); //original list used for computations
  const [elementsToShow, setelementsToShow] = useState(props.elements); //the list that will be shown
  const [filteredElements, setfilteredElements] = useState(props.elements); //the filtered list, if filters have been applied
  const [sortOrder, setsortOrder] = useState(
    Array.from({ length: props.header.length }).fill(0)
  );
  const [showFilterField, setshowFilterField] = useState(
    Array.from({ length: props.elements.length }).fill(0)
  );
  const [filterValues, setfilterValues] = useState(null);
  const [pageNumber, setpageNumber] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);

  const [focusedIndex, setFocusedIndex] = useState(null);
  const inputRefs = useRef([]);

  // Funzione per gestire il click sul pulsante
  const handleButtonClick = (index) => {
    setFocusedIndex(index);
  };

  useEffect(() => {
    // Assegnare il focus all'input corretto quando focusedIndex cambia
    if (focusedIndex !== null && inputRefs.current[focusedIndex]) {
      inputRefs.current[focusedIndex].focus();
    }
  }, [focusedIndex]);

  // const focus = () => inputFocus.current.focus()

  //This is used to rerender the table when an element is deleted or added
  useEffect(() => {
    isNonEmptyArray(props.elements) && setelementsToShow(props.elements);
  }, [props.elements]);

  useEffect(() => {
    if (isNonEmptyArray(props.header)) {
      const _header = props.header.map((h) => {
        h.resize = false;
        return h;
      });

      setheader(_header);
    }
  }, [props.header]);

  useEffect(() => {
    if (filterValues) updateFilteredList();
  }, [filterValues]);

  useEffect(() => {
    if (filteredElements.length > 0) {
      setelementsToShow(showElementsPerPage(filteredElements));
    } else {
      setelementsToShow(showElementsPerPage(originalElements.current));
    }
  }, [pageNumber, rowsPerPage, filteredElements]);

  const showElementsPerPage = (elems, num = pageNumber) => {
    return elems.slice(rowsPerPage * num - rowsPerPage, rowsPerPage * num);
  };

  const showPageNumbers = () => {
    let elems =
      filteredElements && filteredElements.length > 0
        ? filteredElements
        : originalElements.current;
    let showDots = true;
    return Array.from(
      { length: Math.ceil(elems.length / rowsPerPage) },
      (x, i) => i + 1
    ).map((num) => {
      if (
        num === 1 ||
        num === Math.ceil(elems.length / rowsPerPage) ||
        num === pageNumber ||
        num === pageNumber - 1 ||
        (num === pageNumber + 1 &&
          pageNumber + 1 <= Math.ceil(elems.length / rowsPerPage))
      ) {
        return (
          <div
            className={
              pageNumber === num ? "table-page active" : "table-page not-active"
            }
            onClick={() => {
              setpageNumber(num);
              setelementsToShow(showElementsPerPage(elementsToShow, num));
            }}
          >
            {num}
          </div>
        );
      } else {
        if (showDots) {
          showDots = false;
          return (
            <div style={{ marginLeft: "3px", marginRight: "3px" }}>...</div>
          );
        }
      }
    });
  };

  //Function that filters the originalElements array and gives a subset of elements given the filter values found
  const updateFilteredList = () => {
    if (Object.values(filterValues).filter((val) => val).length === 0) {
      setelementsToShow(originalElements.current);
      return;
    }

    //Generating an array with all the occurences found per each columns
    let _elements = [...originalElements.current];
    let flatArray = [];
    Object.keys(filterValues).forEach((fv, fvIndex) => {
      _elements.forEach((element) => {
        Object.keys(element).forEach((key) => {
          //checking if keys are teh same and if the filter value written is included in that specific cell
          if (
            key === fv &&
            isNonEmptyString(element[key]) &&
            isNonEmptyString(filterValues[fv]) &&
            element[key].toLowerCase().includes(filterValues[fv].toLowerCase())
          ) {
            flatArray.push(element);
          }
        });
      });
    });

    let foundTdr = [...flatArray];
    let howManyFilledFilters = Object.keys(filterValues).length;
    //if there are multiple filters, we need to intersect the results (AND operation)
    //Basically, each result I want must respect all the filters
    if (howManyFilledFilters > 1) {
      flatArray = flatArray.map((el) => JSON.stringify(el));

      foundTdr = new Set();
      let count = 1;
      for (let i = 0; i < flatArray.length; i++) {
        count = 1;
        for (let k = 0; k < flatArray.length; k++) {
          if (k === i) continue;
          if (flatArray[i].includes(flatArray[k])) {
            count++;
            if (count === howManyFilledFilters) {
              foundTdr.add(flatArray[i]);
              count = 1;
              break;
            }
          }
        }
      }

      foundTdr = Array.from(foundTdr).map((el) => JSON.parse(el));
    }

    if (
      foundTdr.length === 0 &&
      Object.values(filterValues).filter((val) => val).length > 0
    )
      setfilteredElements([]);
    else if (
      foundTdr.length > 0 &&
      Object.values(filterValues).filter((val) => val).length > 0
    ) {
      setfilteredElements(foundTdr);
    } else {
      setfilteredElements(originalElements.current);
    }
  };

  //updating the originalElements array such that is sorted
  const sortBy = (type, index) => {
    let tmp = null;
    if (sortOrder[index] % 2 === 0) tmp = sortUpOrDown(-1, 1, type);
    else tmp = sortUpOrDown(1, -1, type);
    // const tmp = sortUpOrDown(sortOrder[index] % 2 === 0, type)
    originalElements.current = tmp;

    //updating the elements array such that is sorted and only a subset of them is showed, depending on the page number
    setelementsToShow(showElementsPerPage(tmp));
    updateSortArray(index);
  };

  //Sorting the originalElements array
  const sortUpOrDown = (higher, lower, type) => {
    let tmp = [...originalElements.current];
    tmp = tmp.sort((a, b) => {
      const first = a[type].toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
      const second = b[type].toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
      if (first < second) return lower;
      if (first > second) return higher;
      else return 0;
    });
    return tmp;
  };

  //Toggle sort button
  const updateSortArray = (index) => {
    let tmp = [...sortOrder];
    tmp[index]++;
    setsortOrder(tmp);
  };

  const handleFilterFields = (index) => {
    let tmp = [...showFilterField];
    tmp[index] = !tmp[index];
    setshowFilterField(tmp);
  };

  const handleFilterOnChange = (e, field) => {
    let _filterValues = { ...filterValues };
    if (e.target.value === "") {
      delete _filterValues[field];
      setfilterValues(_filterValues);
    } else {
      setfilterValues({ ...filterValues, [field]: e.target.value });
    }
  };

  return (
    originalElements.current.length > 0 && (
      <div className="table-container">
        <ul className="list-group main">
          <li
            className="list-group-item table-header"
            style={{ position: "sticky", top: "0px", zIndex: 10 }}
          >
            {header &&
              header.map((h, index) => {
                if (!h.hide)
                  return (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        h.tooltip ? (
                          <Tooltip style={{ position: "absolute" }} id={index}>
                            {h.tooltip}
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div
                        style={{
                          maxWidth:
                            header.find((h) => h.resize) && !h.resize
                              ? "0%"
                              : h.resize
                              ? "100%"
                              : h.size
                              ? h.size
                              : "unset",
                          opacity:
                            header.find((h) => h.resize) && !h.resize
                              ? "0"
                              : "1",
                          paddingLeft:
                            header.find((h) => h.resize) && !h.resize
                              ? "0px"
                              : "2px",
                          paddingRight:
                            header.find((h) => h.resize) && !h.resize
                              ? "0px"
                              : "2px",
                          width:
                            header.find((h) => h.resize) && !h.resize
                              ? "0%"
                              : h.resize
                              ? "100%"
                              : h.size
                              ? h.size
                              : "25%",
                          transition: "all 0.5s ease",

                          minHeight: "120px",
                          zIndex: 1,
                        }}
                      >
                        {isNonEmptyString(h.title) && (
                          <span className="action">
                            <div>{h.title}</div>
                            <div>
                              {isSortable && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      style={{ position: "absolute" }}
                                      id={index}
                                    >
                                      Sort table
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faSort}
                                    style={{
                                      marginLeft: props.dense ? "5px" : "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => sortBy(h.field, index)}
                                  />
                                </OverlayTrigger>
                              )}
                              {isSearchable && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      style={{ position: "absolute" }}
                                      id={index}
                                    >
                                      Search
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                    style={{
                                      marginLeft: props.dense ? "5px" : "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleButtonClick(index);
                                      handleFilterFields(index);
                                    }}
                                  />
                                </OverlayTrigger>
                              )}
                              {isCollapsible && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      style={{ position: "absolute" }}
                                      id={index}
                                    >
                                      Expand column
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowsLeftRight}
                                    style={{
                                      marginLeft: props.dense ? "5px" : "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const _header = [...header];
                                      _header[index].resize =
                                        !_header[index].resize;
                                      setheader(_header);
                                    }}
                                  />
                                </OverlayTrigger>
                              )}
                            </div>
                          </span>
                        )}

                        <CrossedInput
                          style={{
                            display: showFilterField[index] ? "unset" : "none",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                          size="80%"
                          noClear
                          object={filterValues}
                          value={
                            filterValues && filterValues[h.field]
                              ? filterValues[h.field]
                              : ""
                          }
                          callback={(e) => handleFilterOnChange(e, h.field)}
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </div>
                    </OverlayTrigger>
                  );
              })}
          </li>

          {elementsToShow.map((el) => (
            <li className="list-group-item">
              {header &&
                header.map((h) => {
                  return Object.keys(el).map((key, index) => {
                    if (h.field === key && !h.hide) {
                      return (
                        <TippedCell
                          style={{
                            maxWidth:
                              header.find((h) => h.resize) && !h.resize
                                ? "0%"
                                : h.resize
                                ? "100%"
                                : h.size
                                ? h.size
                                : "unset",
                            paddingLeft:
                              header.find((h) => h.resize) && !h.resize
                                ? "0px"
                                : "2px",
                            paddingRight:
                              header.find((h) => h.resize) && !h.resize
                                ? "0px"
                                : "2px",
                            width:
                              header.find((h) => h.resize) && !h.resize
                                ? "0%"
                                : h.resize
                                ? "100%"
                                : "unset",
                            transition: "width 0.5s ease, max-width 0.5s ease",
                          }}
                          content={el[key] || ""}
                          index={index}
                          errorObject={
                            el.broken &&
                            el.broken.isBroken &&
                            el.broken.coloredFieldNames.includes(key)
                              ? el.broken
                              : null
                          }
                        ></TippedCell>
                      );
                    }
                  });
                })}
              {props.cellActions &&
                props.cellActions.map((action) => {
                  if (action.type === "delete") {
                    return (
                      <DeleteAction
                        element={el}
                        type={action.elementName}
                        delete={action.method}
                        size={action.size}
                      />
                    );
                  }
                  if (action.type === "show") {
                    return (
                      <ShowAction
                        element={el}
                        header={props.header}
                        type={action.elementName}
                        size={action.size}
                      />
                    );
                  }
                })}
            </li>
          ))}
        </ul>
        {originalElements.current && (
          <div
            className="rowDiv j-right"
            style={{
              marginTop: "10px",
              marginRight: "30px",
              paddingBottom: "10px",
            }}
          >
            <Dropdown
              onSelect={(e) => {
                setrowsPerPage(parseInt(e));
                setpageNumber(1);
              }}
              style={{ marginRight: "10px" }}
            >
              <Dropdown.Toggle id="dropdown-basic">Rows</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                <Dropdown.Item eventKey="20">20</Dropdown.Item>
                <Dropdown.Item eventKey="30">30</Dropdown.Item>
                <Dropdown.Item eventKey="50">50</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {showPageNumbers()}
          </div>
        )}
      </div>
    )
  );
}

export default ListTable;
