import React, { useState, useEffect } from "react";
import { NavMenu } from "./NavMenu";
import Breadcrumb from "./UI/Breadcrumb/Breadcrumb";
// import Header from "./UI/Header/Header"
import { useSnackbar } from "../contexts/snackbarContext";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { useConfig } from "../contexts/configContext";
import parse from "html-react-parser";
import Fade from "react-bootstrap/Fade";

import axios from "axios";
import useHttpApi from "./useHttpApi";

const Layout = (props) => {
  const [logo, setlogo] = useState(null);
  const [protectionGroup, setprotectionGroup] = useState(null);
  const snackbar = useSnackbar();
  const config = useConfig();
  const httpApi = useHttpApi();
  const [breadcrumb, setbreadcrumb] = useState({
    label: "",
    position: "",
    url: "",
    size: "86%",
  });

  const fadeInStyle = {
    borderRadius: "10px",
    padding: "8px",
    boxShadow: "10px 10px 8px black",
    maxWidth: "40%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "4px solid transparent",
  };

  useEffect(() => {
    getConfigFile().then((res) => {
      config.setloading(false);
      config.setConfigInfo(res);
      axios.defaults.baseURL = res.apiUrl;
      httpApi.callGetAPI({
        url: "json/snsm_pg_config",
        snack: { show: false },
        successCallback: (data) => {
          if (data.snsm_pg_enabled === "ENABLED") {
            setprotectionGroup({
              name: data.snsm_pg_name,
              members: data.snsm_pg_members || [],
            });
          }
        },
      });
    });
    const logo = axios({ baseURL: "", url: "/Logo.png" });
    logo.then((data) => setlogo(data));
  }, []);

  const getConfigFile = async () => {
    const data = await fetch("config.json").then((data) => data);
    const jsonData = await data.json();
    return jsonData;
  };

  useEffect(() => {
    if (
      snackbar &&
      snackbar.snackbarInfo &&
      snackbar.snackbarInfo.status !== ""
    ) {
      switch (snackbar.snackbarInfo.status) {
        case "success":
          toast.custom(
            (t) => (
              <Fade in={true} appear={true}>
                <div
                  style={{
                    ...fadeInStyle,
                    background:
                      "linear-gradient(#e6ffcc, #e6ffcc) padding-box,linear-gradient(to right, #afc283, #5a7900) border-box",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      fontSize: "16pt",
                    }}
                  >
                    ✅
                  </div>
                  {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #4cab55",
                      borderRadius: "15px",
                      marginLeft: "30px",
                    }}
                  ></div> */}
                  <div style={{ marginLeft: "10px" }}>
                    {parse(snackbar.snackbarInfo.message)}
                  </div>
                </div>
              </Fade>
            ),
            {
              duration: snackbar.snackbarInfo.duration || 6000,
            }
          );
          break;
        case "warning":
          toast.custom(
            (t) => (
              <Fade in={true} appear={true}>
                <div
                  style={{
                    ...fadeInStyle,
                    backgroundColor: "#ffe6b3",
                    background:
                      "linear-gradient(#ffe6b3, #ffe6b3) padding-box,linear-gradient(to right, #f6c574, #e99328) border-box",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      fontSize: "16pt",
                    }}
                  >
                    ⚠️
                  </div>
                  {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #f3b669",
                      borderRadius: "15px",
                    }}
                  ></div> */}

                  <div style={{ marginLeft: "10px" }}>
                    {parse(snackbar.snackbarInfo.message)}
                  </div>
                </div>
              </Fade>
            ),
            {
              duration: snackbar.snackbarInfo.duration || 6000,
            }
          );
          break;
        case "error":
          // toast.error(snackbar.snackbarInfo.message, {
          //   duration: 6000,
          //   style: {
          //     backgroundColor: '#ffc2b3',
          //     border: '1px solid #ff8566',
          //     borderRadius: '5px',
          //     padding: '8px',
          //     boxShadow: '10px 10px 8px #454545',
          //   },
          // })
          toast.custom(
            (t) => (
              <Fade in={true} appear={true}>
                <div
                  style={{
                    ...fadeInStyle,
                    background:
                      "linear-gradient(#ffc2b3, #ffc2b3) padding-box,linear-gradient(to right, #c28383, #790000) border-box",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      fontSize: "16pt",
                    }}
                  >
                    ❌
                  </div>
                  {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #790000",
                      borderRadius: "15px",
                    }}
                  ></div> */}
                  <div style={{ marginLeft: "10px" }}>
                    {parse(snackbar.snackbarInfo.message)}
                  </div>
                </div>
              </Fade>
            ),
            {
              duration: snackbar.snackbarInfo.duration || 6000,
            }
          );
          break;
        case "info":
          toast.custom(
            (t) => (
              <Fade in={true} appear={true}>
                <div
                  style={{
                    ...fadeInStyle,
                    background:
                      "linear-gradient(#d3eaf2, #d3eaf2) padding-box,linear-gradient(to right, #83b1c2, #005879) border-box",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      fontSize: "16pt",
                    }}
                  >
                    ℹ️
                  </div>
                  {/* <div
                    style={{
                      height: "100%",
                      width: "1px",
                      border: "2px solid #65b1e9",
                      borderRadius: "15px",
                    }}
                  ></div> */}
                  <div style={{ marginLeft: "10px" }}>
                    {parse(snackbar.snackbarInfo.message)}
                  </div>
                </div>
              </Fade>
            ),
            {
              duration: snackbar.snackbarInfo.duration || 6000,
            }
          );
          break;
      }
    }
  }, [snackbar]);

  const handleBreadcrumb = (label, position, url) => {
    setbreadcrumb({
      ...breadcrumb,
      label: label,
      position: position,
      url: url,
    });
  };

  return (
    <>
      <Toaster gutter={24} />
      {/* {!config.loading && <Header />} */}
      <NavMenu capabilities={props.capabilities} />

      <div
        className="rowDiv j-between"
        style={{
          width: "100%",
          backgroundColor: "#1e2b2d",
          paddingTop: "20px",
        }}
      >
        {breadcrumb && (
          <Breadcrumb
            size={breadcrumb.size}
            label={breadcrumb.label}
            position={breadcrumb.position}
            url={breadcrumb.url}
          />
        )}
        <div style={{ padding: "10px 15px 30px 15px" }}>
          <img src="/Logo.png" width="174px" height="36px"></img>
        </div>
      </div>
      <div className="sepp-main-info">
        <div
          className="rowDiv j-between"
          style={{ width: "98%", marginLeft: "1%" }}
        >
          <div className="colDiv j-center">
            <div
              className="with-breadcrumb"
              style={{
                color: config.namespace ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              SEPP ID: {config.namespace || "NOT SET!"}
            </div>
            <div
              className="with-breadcrumb"
              style={{
                color: config?.configInfo?.tenant ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              Tenant: {config?.configInfo?.tenant || "NOT SET!"}
            </div>
            <div
              className="with-breadcrumb"
              style={{
                color: config?.configInfo?.seppCommonName ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
                textTransform: "unset",
              }}
            >
              SNI: {config?.configInfo?.seppCommonName || "NOT SET!"}
            </div>
          </div>
          <div className="colDiv j-center">
            <div
              className="with-breadcrumb"
              style={{
                color: protectionGroup?.name ? "white" : "orange",
                width: "100%",
                marginLeft: "0%",
              }}
            >
              PROTECTION GROUP: {protectionGroup?.name || "NOT SET!"}
            </div>
            {protectionGroup?.members &&
              protectionGroup?.members.length > 0 && (
                <div
                  className="with-breadcrumb"
                  style={{
                    color: protectionGroup?.members ? "white" : "orange",
                    width: "100%",
                    marginLeft: "0%",
                  }}
                >
                  MEMBERS: {protectionGroup.members?.join(", ")}
                </div>
              )}
          </div>
        </div>
      </div>
      {!config.loading && (
        <div
          className="App"
          style={{ backgroundColor: "#1e2b2d", width: "86%" }}
        >
          {props.children(handleBreadcrumb)}
        </div>
      )}
    </>
  );
};

export default Layout;
